
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "motor-insurance-package",
  components: {},
  data() {
    return {
      url: ""
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Motor Insurance", ["Insurance Packages"]);
    });
  },
  created() {
    // Check if motor policies exist
    this.hasMotorPolicies();

    //Set page title
    document.title = "Motor Insurance Package | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasMotorPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.url =
              data.data.length == 0
                ? "motor-insurance-package-quote"
                : "motor-insurance-package-existing-policy-quote";
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  }
});
